import "./MarkdownHandler.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import CodeHighlight from "./CodeHighlighter/CodeHighlight";
import ADItem from "../AdItems/ADItem";
import { Button } from "@mui/material";
import MUIButton from "../MarkdownButton/MarkdownButton";
import MUIImage from "../MarkdownImage/MarkdownImage";


export default function MarkdownHandler({ input }) {
  return (
    <ReactMarkdown
      className="markdown"
      children={input}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        code: CodeHighlight,
        ad: ADItem,
        button: ({ node, ...props }) => <MUIButton destination={node.properties.destination} {...props} />,
        img: ({ node, ...props }) => <MUIImage {...props} />

      }}
    />
  );
}


