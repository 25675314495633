import { Box, Typography } from "@mui/material";

export default function MUIImage({ src, alt, title }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginY: 3,
      }}
    >
      <Box
        component="img"
        src={src}
        alt={alt}
        title={title}
        sx={{
          maxWidth: "100%",
          borderRadius: 2,
          boxShadow: 3,
          marginRight : 2
        }}
      />
      {alt && (
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ marginTop: 1 }}
        >
          {alt}
        </Typography>
      )}
    </Box>
  );
}
